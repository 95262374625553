import React from "react"
import { ThemeProvider } from "@mui/material/styles"
import { CssBaseline } from "@mui/material"

import theme from './src/theme'

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>
    <CssBaseline />
    {element}
  </ThemeProvider>
}